/**
 * @file    utils.ts - Exports a function to merge classNames.
 *
 *          Generated from `shadcn/ui`: https://ui.shadcn.com/
 *
 * @author  Bryan Hoang <bryan@distributve.network>
 * @date    Nov. 2023
 */

import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Accepts different lists of classnames and returns the merged result.
 *
 * @param inputs - the list of classnames.
 * @returns The merged list of classnames.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
