/**
 * @file    app.tsx - Exports an component which provides context for its
 *          children.
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Apr. 2023
 */

import { WorkerProvider } from 'use-dcp-worker';

import { ThemeProvider } from '@/lib/theme';
import { UseQueryParamsProvider } from '@/lib/use-query-params';

type AppProviderProps = {
  children: React.ReactNode;
};

/**
 * Renders a provider component for an application.
 *
 * @param props - The component's properties.
 * @param props.children - The components to provide for.
 * @returns A rendered wrapper around the children that are provided for.
 */
export function AppProvider({ children }: AppProviderProps): JSX.Element {
  return (
    <UseQueryParamsProvider>
      <ThemeProvider
        disableTransitionOnChange
        enableSystem
        attribute="class"
        defaultTheme="dark"
      >
        <WorkerProvider>{children}</WorkerProvider>
      </ThemeProvider>
    </UseQueryParamsProvider>
  );
}
