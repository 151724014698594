/**
 * @file    use-query-params.tsx - Re-exports a `useQueryParams` provider
 *                                 configured for the app.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Sept. 2023
 */

import { NextAdapter } from 'next-query-params';
import type { ReactNode } from 'react';
import { QueryParamProvider } from 'use-query-params';

interface UseQueryParamsProviderProps {
  children: ReactNode;
}

/**
 * Renders a component that provides `useQueryParams` to the children.
 *
 * @param props - Component properties.
 * @param props.children - The children that'll receive the provided theme.
 * @returns The rendered component.
 */
export function UseQueryParamsProvider({
  children,
}: UseQueryParamsProviderProps): JSX.Element {
  return (
    <QueryParamProvider
      adapter={NextAdapter}
      options={{ removeDefaultsFromUrl: true }}
    >
      {children}
    </QueryParamProvider>
  );
}
