/**
 * @file    <Header> - Serves as the navigation for the website
 * @author  Kevin Yu
 * @date    Nov. 2023
 */

import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import dcpLogoWordmark from '@/assets/img/nav_logo.png';
import closedIcon from '@/assets/img/nav-toggle.png';
import openIcon from '@/assets/img/nav-toggle.png';
import { cn } from '@/lib/utils';

type MenuIconProps = {
  isOpen: boolean;
};

/**
 * Renders an image representing the menu icon.
 *
 * @param props - The component's properties.
 * @param props.isOpen - The open and closed state of the icon.
 * @returns The rendered menu icon.
 */
function MenuIcon({ isOpen }: MenuIconProps): JSX.Element {
  return (
    <Image src={isOpen ? openIcon : closedIcon} alt="Hamburger menu icon" />
  );
}

const DISTRIBUTED_COMPUTER_URL = 'https://distributed.computer/';

const LINKS = [
  { label: 'DCP PLATFORM', href: 'https://distributive.network/platform' },
  {
    label: 'SOLUTIONS',
    href: 'https://distributive.network/',
  },
  { label: 'ABOUT', href: 'https://distributive.network/about' },
];

/**
 * Header component for the website
 *
 * @returns The rendered component
 */
export function Header(): JSX.Element {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isBigScreen = useMediaQuery({ query: '(min-width: 1280px)' });

  const toggleNav = () => {
    setIsNavOpen((open) => !open);
  };

  useEffect(() => {
    if (isBigScreen) {
      setIsNavOpen(true);
      return;
    }

    setIsNavOpen(false);
  }, [isBigScreen]);
  return (
    <header className="main-header relative min-h-[50px] sm:min-h-[75px] z-[100] dcp-gradient">
      <nav className="dcp-gradient fixed w-[100%] xl:relative flex justify-between min-h-[50px] sm:min-h-[75px] px-[15px] sm:px-[30px] xl:px-[30px] items-center !overflow-visible">
        <div className="flex justify-center items-center">
          <Link
            href={DISTRIBUTED_COMPUTER_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="relative w-[115px] md:w-[200px] min-h-[20px] md:min-h-[36px]">
              <Image alt="DCP Logo" src={dcpLogoWordmark} fill />
            </div>
          </Link>
        </div>

        {/* Toggle Button */}
        <button className="flex visible xl:invisible" onClick={toggleNav}>
          <MenuIcon isOpen={isNavOpen} />
        </button>

        {/* Nav Links */}
        <div
          className={cn(
            'absolute z- xl:relative xl:w-[auto] left-0 right-0 bottom-0 top-[50px] sm:top-[75px] min-h-[93vh] xl:min-h-[auto] xl:top-0 flex flex-col xl:pt-[0] dcp-gradient xl:!bg-none xl:flex-row justify-center items-center gap-[40px]',
            !isNavOpen && 'hidden',
          )}
        >
          {LINKS.map(({ label, href }) => (
            <Link
              key={label}
              href={href}
              target="_blank"
              rel="noreferrer noopener"
              className="hover:underline text-[white] hover:text-white focus:text-white"
            >
              {label}
            </Link>
          ))}

          {/* DCP PORTAL */}
          <Link
            className="bg-[#1AA473] text-[white] py-[8px] px-[20px] focus:text-white hover:no-underline hover:text-white"
            href="https://secure.distributed.computer/users/sign_in"
            target="_blank"
          >
            DCP PORTAL
          </Link>
        </div>
      </nav>
    </header>
  );
}
