/**
 * @file    GoogleTagManager.tsx - A component that handles adding Google Tag
 *          Manager to the app.
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    July. 2023
 */

import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import * as gtag from '@/lib/gtag';

/**
 * Conditionally renders script elements for Google Tag Manager based on whether
 * the `GA_TRACKING_ID` environment varialbe is defined.
 *
 * @returns Script elements, or nothing.
 */
export function GoogleTagManager(): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return gtag.GA_TRACKING_ID ? (
    <>
      <Script id="gtag-setup">
        {`
          window.dataLayer = window.dataLayer ?? [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', '${gtag.GA_TRACKING_ID}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
    </>
  ) : (
    <></>
  );
}
