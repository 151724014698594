/**
 * @file    gtag.tsx - Exports library functions used to interact with tags from
 *          Google Analytics.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Apr. 2023
 */

import { GA_TRACKING_ID } from '@/config';

export { GA_TRACKING_ID };

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 *
 * @param url - The URL of the viewd page.
 */
export function pageview(url: string): void {
  if (GA_TRACKING_ID) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
}
