/**
 * @file    _app.tsx - Exports an 'app' component which controls page
 *          initialization.
 *
 *          See {@link https://nextjs.org/docs/advanced-features/custom-app}.
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Apr. 2023
 */

import '../styles/main.css';

import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import type { AppProps } from 'next/app';
import { ErrorBoundary } from 'react-error-boundary';

import { Head } from '@/components/Head';
import { Layout } from '@/components/Layout';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AppProvider } from '@/providers/app';

function AlertDestructive({ error }: { error: Error }) {
  let alertMessage;
  if (error.message.includes('dcp-client')) {
    alertMessage = (
      <>
        <code>dcp-client</code> could not be loaded.
      </>
    );
  } else {
    alertMessage = error.message;
  }

  return (
    <div className="flex justify-center items-center h-full">
      <Alert variant="destructive" className="w-auto">
        <ExclamationTriangleIcon className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{alertMessage}</AlertDescription>
      </Alert>
    </div>
  );
}

/**
 * Renders a component which initializes the pages.
 *
 * Sets the default page title, and sets up google analytics.
 *
 * @param props - The component's properties.
 * @param props.Component - The page component to rende.
 * @param props.pageProps - The props to pass to the page.
 * @returns The rendered page component.
 */
export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <Head title="DCP Web Worker" />
      <Layout>
        <ErrorBoundary FallbackComponent={AlertDestructive}>
          <AppProvider>
            <Component suppressHydrationWarning {...pageProps} />
          </AppProvider>
        </ErrorBoundary>
      </Layout>
    </>
  );
}
