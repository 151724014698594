/**
 * @file    theme.tsx - Re-exports a Themes provider configured for the app.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Sept. 2023, Nov. 2023
 */

import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { type ThemeProviderProps } from 'next-themes/dist/types';

/**
 * Provides theme capabilities to its children.
 *
 * @param props - Component properties.
 * @param props.children - Child components to provide the theme to.
 * @returns The children with a provided theme.
 */
export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  return <NextThemesProvider {...props}>{children}</NextThemesProvider>;
}
