/**
 * @file    Layout.tsx - Exports a Layout component.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    July 2023
 */

import type { ReactNode } from 'react';

import { Header } from './Header';

type LayoutProps = {
  children: ReactNode;
};

/**
 * Renders its children with common layout components around it.
 *
 * @param props - Component properties.
 * @param props.children - The component(s) to wrap.
 * @returns The children wrapped with other layout elements.
 */
export function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />
      <main className="bg-[black] w-[320px] min-w-[100vw]">{children}</main>
    </>
  );
}
