/**
 * @file    Head.tsx - Exports a command head element for pages to use.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    July 2023
 */

import NextHead from 'next/head';

import { GoogleTagManager } from './GoogleTagManager';

type HeadProps = {
  title: string;
};

/**
 * Renders a component representing elements that go in the `head` of the html
 * document.
 *
 * @param props - Component properties.
 * @param props.title - The title of the page.
 * @returns The rendered element.
 */
export function Head({ title }: HeadProps): JSX.Element {
  return (
    <>
      <NextHead>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </NextHead>
      <GoogleTagManager />
    </>
  );
}
